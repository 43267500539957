import { Transition } from "@headlessui/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import DOMPurify from "dompurify";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import {
  deleteCollabThread,
  getAllCollabThread,
  updateCollabThread,
} from "../../api";
import { CollabThreadParams, CollaborationThread } from "../../api/types";
import { useCurrentUser } from "../AuthProvider";
import Avatar from "../Avatar";
import { ConfirmationModal } from "../ConfirmationModal";
import SpinnerCustom from "../SpinnerCustom";
import { ShowSnackBar } from "../Utils/supportMessage";
import ThreadActionButtons from "./ThreadActionButtons";
import { ThreadForm } from "./ThreadForm";

type ForumViewProps = {
  newThreadId?: number | undefined;
  statusFilter?: string | number;
  statusSorting?: string | number;
  query?: string;
};

type TooltipType = {
  threadId: number;
  type: "Edit" | "Delete" | "Close";
};

const ForumView = ({
  newThreadId,
  statusFilter = "",
  statusSorting = "",
  query = "",
}: ForumViewProps) => {
  const [collaborationThreads, setCollaborationThreads] = useState<
    CollaborationThread[]
  >([]);
  const { user: currentUser } = useCurrentUser();
  const gridRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(0);

  const [selectedThread, setSelectedThread] =
    useState<CollaborationThread | null>(null);

  const [showConfirmationDelete, setShowConfirmationDelete] =
    useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  const [showTooltip, setShowTooltip] = useState<TooltipType | null>(null);
  const [tooltipText, setTooltipText] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const params: Record<string, any> = {
      page,
      limit: 15,
      status: statusFilter,
      sort: statusSorting,
      q: query,
    };
    setIsLoading(true);
    getAllCollabThread(new URLSearchParams(params))
      .then((response) => {
        if (response.data) {
          const responseData = response.data.data;
          if (newThreadId && page === 1) {
            setCollaborationThreads(responseData.items);
          } else {
            setCollaborationThreads([
              ...collaborationThreads,
              ...responseData.items,
            ]);
          }
          setPages(responseData.pages);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newThreadId, page]);

  useEffect(() => {
    const params: Record<string, any> = {
      page: 1,
      limit: 15,
      status: statusFilter,
      sort: statusSorting,
      q: query,
    };
    setIsLoading(true);
    setCollaborationThreads([]);
    getAllCollabThread(new URLSearchParams(params))
      .then((response) => {
        if (response.data) {
          const responseData = response.data.data;
          setCollaborationThreads(responseData.items);
          setPage(1);
          setPages(responseData.pages);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, statusSorting, query]);

  //Update Thread
  const updateThread = (thread: CollaborationThread) => {
    if (thread) {
      collaborationThreads.forEach((item, index) => {
        if (item.id === thread.id) {
          collaborationThreads[index] = thread;
        }
      });
      setCollaborationThreads(collaborationThreads);
    }
    setSelectedThread(null);
  };

  const updateCloseThread = (thread: CollaborationThread) => {
    if (thread) {
      let payload: CollabThreadParams = {
        title: thread.title,
        content: thread.content,
        resolved_at: thread.resolved_at ? null : new Date().toISOString(),
      };
      updateCollabThread(thread.id, payload).then((response) => {
        if (response.data) {
          collaborationThreads.forEach((item, index) => {
            if (item.id === thread.id) {
              collaborationThreads[index] = response.data.data;
            }
          });
          setCollaborationThreads([...[], ...collaborationThreads]);
        }
      });
    }
    setSelectedThread(null);
  };

  const deleteThread = (thread: CollaborationThread | null) => {
    if (thread) {
      setDeleting(true);
      deleteCollabThread(thread.id)
        .then((response) => {
          if (response.data.success) {
            setCollaborationThreads(
              collaborationThreads.filter(
                (collabThread) => collabThread.id !== thread.id,
              ),
            );
            ShowSnackBar("You deleted thread.");
            setShowConfirmationDelete(false);
          }
        })
        .finally(() => setDeleting(false));
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById("sidebar-wide-view");
      if (
        page < pages &&
        element &&
        element.offsetHeight + element.scrollTop >= element.scrollHeight
      ) {
        setPage(page + 1);
      }
    };
    document
      .getElementById("sidebar-wide-view")
      ?.addEventListener("scroll", handleScroll);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      document
        .getElementById("sidebar-wide-view")
        ?.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaborationThreads]);

  const Tooltip = ({ show, text }: { show: boolean; text: string }) => {
    return (
      <Transition
        show={show}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute -bottom-10 right-0 z-top w-24 rounded-xl border border-gray-200 bg-gray-800 p-2 text-2xs text-white drop-shadow-2xl">
          {text}
        </div>
      </Transition>
    );
  };

  return (
    <div className="mt-5">
      {isLoading ? (
        <div className={`mx-auto w-max pl-6 text-base lg:pl-14`}>
          <SpinnerCustom />
        </div>
      ) : (
        collaborationThreads.length === 0 && (
          <div className="grid justify-center">
            <div className="flex flex-col items-center">
              <img
                src="/no-files.png"
                alt="No Threads Found"
                className="-mt-10 h-48 w-48 md:-mt-16 md:h-96 md:w-96"
              />
              <div className="-mt-4 text-xs font-semibold md:-mt-12 md:text-base">
                No Threads Found
              </div>
            </div>
          </div>
        )
      )}
      <div>
        {collaborationThreads.length > 0 &&
          collaborationThreads.map((thread: CollaborationThread) => {
            const isResolved = thread.resolved_at;
            return (
              <div
                key={thread.id}
                className="mt-3 flex w-full flex-row space-y-6"
              >
                <div className="grid-col grid w-full">
                  <div className="rounded-t-lg bg-slate-50 p-2">
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row items-center space-x-2">
                        <Avatar
                          name={thread.owner.name}
                          size={20}
                          url={thread.owner.image_url}
                          className="text-xs"
                        />
                        <div className="text-2xs text-black">
                          {thread.owner.name}
                        </div>
                        <div className="-translate-y-0.5 font-bold">{"."}</div>
                        <ReactTimeAgo
                          date={new Date(thread.created_at)}
                          locale="en-US"
                          className="text-2xs"
                        />
                      </div>

                      <div>
                        {thread.owner.id === currentUser.id && (
                          <div className="flex flex-row items-center">
                            <PencilIcon
                              className="h-4 w-4 cursor-pointer stroke-blue-900"
                              onMouseEnter={() => {
                                setShowTooltip({
                                  threadId: thread.id,
                                  type: "Edit",
                                });
                                setTooltipText("Edit thread");
                              }}
                              onMouseLeave={() => {
                                setShowTooltip(null);
                              }}
                              onClick={() => {
                                setSelectedThread(thread);
                              }}
                            />
                            <Tooltip
                              show={
                                thread.id === showTooltip?.threadId &&
                                showTooltip.type === "Edit"
                              }
                              text={tooltipText}
                            />

                            <TrashIcon
                              className="h-4 w-4 cursor-pointer stroke-red-600"
                              onMouseEnter={() => {
                                setShowTooltip({
                                  threadId: thread.id,
                                  type: "Delete",
                                });
                                setTooltipText("Delete thread");
                              }}
                              onMouseLeave={() => {
                                setShowTooltip(null);
                              }}
                              onClick={() => {
                                setShowConfirmationDelete(true);
                                setSelectedThread(thread);
                              }}
                            />
                            <Tooltip
                              show={
                                thread.id === showTooltip?.threadId &&
                                showTooltip.type === "Delete"
                              }
                              text={tooltipText}
                            />

                            <CheckCircleIcon
                              className={`h5 w-5 cursor-pointer ${
                                isResolved
                                  ? "fill-green-700 stroke-white"
                                  : "fill-gray-400 stroke-white"
                              }`}
                              onClick={() => updateCloseThread(thread)}
                              onMouseEnter={() => {
                                setShowTooltip({
                                  threadId: thread.id,
                                  type: "Close",
                                });
                                setTooltipText("Close thread");
                              }}
                              onMouseLeave={() => {
                                setShowTooltip(null);
                              }}
                            />
                            <Tooltip
                              show={
                                thread.id === showTooltip?.threadId &&
                                showTooltip.type === "Close"
                              }
                              text={tooltipText}
                            />
                            {isResolved && (
                              <div className="ml-1 text-2xs text-green-700">
                                Resolved
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mb-4 w-full rounded-b-lg bg-white pb-2 pl-2 pr-2 shadow-sm">
                    {/* Show editing thread */}
                    {selectedThread &&
                    selectedThread.id === thread.id &&
                    !showConfirmationDelete ? (
                      <ThreadForm
                        thread={thread}
                        onClose={() => setSelectedThread(null)}
                        onSuccess={updateThread}
                      />
                    ) : (
                      <>
                        <Link
                          href={{
                            pathname: `/collab/${thread.id}`,
                          }}
                          as={`/collab/${thread.id}`}
                          passHref
                          legacyBehavior
                        >
                          <div className="mt-3 cursor-pointer text-xs font-bold text-blue-900">
                            {thread.title}
                          </div>
                        </Link>

                        <div
                          className="no-tailwindcss-base mt-3 text-2xs"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(thread.content_html, {
                              FORBID_TAGS: ["style"],
                            }),
                          }}
                        />
                      </>
                    )}

                    <ThreadActionButtons thread={thread} />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <ConfirmationModal
        open={showConfirmationDelete}
        title={`Are you sure to delete this thread?`}
        subtitle="This action cannot be undone"
        processing={deleting}
        processingText={"Deleting..."}
        submitButtonText={"Delete"}
        onClose={() => {
          setShowConfirmationDelete(false);
          setSelectedThread(null);
        }}
        onSubmit={() => deleteThread(selectedThread)}
      />
    </div>
  );
};

export default ForumView;
